<template>
  <div class="body1">
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link :to="{name: 'home'}">{{ $t('home') }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        {{ $t('messages') }}
      </a-breadcrumb-item>
    </a-breadcrumb>
    <a-row :gutter="[15,0]" style="margin-top: 20px">
      <a-col :span="5">
        <a-menu mode="inline" :selectedKeys="['messages']">
          <a-menu-item key="settings">
            <template #icon>
              <SettingOutlined />
            </template>
            <router-link :to="{name: 'account_setting'}">{{ $t('settings') }}</router-link>
          </a-menu-item>
          <a-menu-item key="balances">
            <template #icon>
              <AccountBookOutlined />
            </template>
            <router-link :to="{name: 'account_credit_balance'}">{{ $t('account_credit_balance') }}</router-link>
          </a-menu-item>
          <a-menu-item key="messages">
            <template #icon>
              <AlertOutlined />
            </template>
            <router-link :to="{name: 'account_message'}"><a-badge :count="currentUser.UnreadMesNumber" :offset="[100, 5]">{{ $t('messages') }}</a-badge></router-link>
          </a-menu-item>
        </a-menu>
      </a-col>
      <a-col :span="19" class="border list">
        <div>
          <a-form name="search_form" layout="inline" :hideRequiredMark="true" autocomplete="off" :model="searchForm" style="padding-left: 5px;">
            <a-form-item name="noticeType" :label="$t('message_type')">
              <a-select v-model:value="searchForm.noticeType" :options="messageTypeOptions" style="width:300px"></a-select>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" @click="onSearch">{{ $t('search') }}</a-button>
            </a-form-item>
          </a-form>
        </div>
        <a-divider />
        <template v-if="!loading">
          <div>
            <table>
              <thead>
                <tr>
                  <th style="width:30px"><a-checkbox v-model:checked="allChecked"></a-checkbox></th>
                  <th>{{ $t('message') }}</th>
                  <th style="width:150px">{{ $t('time') }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(item,index) in dataPaging.Rows" v-bind:key="index">
                  <tr :class="item.read_time > 0 ?'read':''">
                    <td><a-checkbox v-model:checked="arrChecked[index]"></a-checkbox></td>
                    <td>
                      <div @click="readMessage(item, index)">
                        <a-badge status="warning" v-if="item.read_time === 0" />
                        <strong>{{ item.title }}</strong>
                      </div>
                      <div class="content" v-if="arrOpenMes[index]">
                        <div v-html="item.content">
                        </div>
                      </div>
                    </td>
                    <td>{{ dayjs.unix(item.utime).format('YYYY-MM-DD HH:mm:ss') }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div style="display: flex;justify-content: space-between;align-items:center;padding-left: 10px">
            <a-space>
              <a-checkbox v-model:checked="allChecked"></a-checkbox>
              <a-button size="small" :loading="arrLoading[0]" :disabled="!arrChecked.includes(true)" @click="deleteSelected">{{ $t('delete') }}</a-button>
              <a-button size="small" :loading="arrLoading[1]" :disabled="!arrChecked.includes(true)" @click="readSelected">{{ $t('mark_read') }}</a-button>
              <a-button size="small" :loading="arrLoading[2]" @click="allRead">{{ $t('all_read') }}</a-button>
            </a-space>
            <a-pagination v-model:current="pageIndex" :total="dataPaging.Total" :showSizeChanger="false" :showQuickJumper="true" :show-total="total => $t('total_items', {s: total})"/>
          </div>
        </template>
        <template v-else>
          <a-spin :spinning="loading" size="large" style="width:100%;padding: 100px 0px">
          </a-spin>
        </template>

      </a-col>
    </a-row>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  AccountBookOutlined,
  SettingOutlined,
  AlertOutlined
} from '@ant-design/icons-vue';
import {getNoticeList,readAllNotice,readNotice,deleteNotice} from "@/services/account";
import {isEmpty} from "@/utils/util";
import {computed} from "vue";

export default {
  name: "Message",
  components: {
    AccountBookOutlined,
    SettingOutlined,
    AlertOutlined
  },
  data(){
    const currentUser = computed(() => {
      return this.$store.getters["account/getUser"]
    })

    let messageTypes = this.$tm('message_types')
    let messageTypeOptions = [{ value: '', label: this.$t('all') }]
    for(let key in messageTypes) {
      messageTypeOptions.push({ value: key, label: messageTypes[key] })
    }

    return {
      dayjs,
      isZhCn: this.$store.getters["setting/getLanguage"] === 'zh_CN',
      currentUser,
      messageTypeOptions,
      searchForm:{
        noticeType: ''
      },
      allChecked: false,
      arrChecked: [],
      arrOpenMes: [],
      loading: false,
      dataPaging: { PageIndex:1,PageTotal:0,PageSize:10,Total:0,Rows:[]},
      pageIndex: 1,
      pageSize: 10,
      arrLoading: [false, false, false]
    }
  },
  mounted() {
    this.search()
  },
  watch:{
    pageIndex: function () {
      this.search()
    },
    allChecked: function(value) {
      this.dataPaging.Rows.forEach((item, index) => {
        this.arrChecked[index] = value
      })
    }
  },
  methods:{
    isEmpty,
    onSearch(){
      if(this.pageIndex != 1)
        this.pageIndex = 1
      else
        this.search()
    },
    search(){
      let params = {}
      params.PageIndex = this.pageIndex
      params.PageSize = this.pageSize
      params.NoticeType = this.searchForm.NoticeType

      this.loading = true
      getNoticeList(params).then(res => {
        this.loading = false
        const resData = res.data
        if (resData.Success) {
          this.dataPaging = resData.Data
          this.arrChecked = []
          this.arrOpenMes = []
          this.dataPaging.Rows.forEach((item, index) => {
            this.arrChecked[index] = false
            this.arrOpenMes[index] = false
            if(item.notice_type === 10) {
              if(this.isZhCn){
                item.title = '[延时支付] 请及时支付订单 ' + item.ref_id
                item.content = '<a href="/booking/detail/' + item.ref_id + '" target="_blank">' + this.$t('booking_detail') + '</a>'
              } else {
                item.title = '[Deferred Payment] Please pay the booking in time, ' + item.ref_id
                item.content = '<a href="/booking/detail/' + item.ref_id + '" target="_blank">' + this.$t('booking_detail') + '</a>'
              }
            }
            if(item.notice_type === 15) {
              if(this.isZhCn) {
                item.title = '[延时支付] 未支付订单已被取消 ' + item.ref_id
                item.content = '<a href="/booking/detail/' + item.ref_id + '" target="_blank">' + this.$t('booking_detail') + '</a>'
              }else{
                item.title = '[Deferred Payment] The unpaid booking has been cancelled, ' + item.ref_id
                item.content = '<a href="/booking/detail/' + item.ref_id + '" target="_blank">' + this.$t('booking_detail') + '</a>'
              }
            }
            if(item.notice_type === 0) {
              if (this.isZhCn) {
                item.title = '[系统通知] ' + item.title_cn
                item.content = item.content_cn
              } else {
                item.title = '[System Notification] ' + item.title_en
                item.content = item.content_en
              }
            }
            if(item.notice_type === 20) {
              if (this.isZhCn) {
                item.title = '[工单通知] ' + item.title_cn
                item.content = '<a href="/booking/detail/' + item.ref_id + '" target="_blank">' + this.$t('booking_detail') + '</a>'
              } else {
                item.title = '[Ticket Notification] ' + item.title_en
                item.content = '<a href="/booking/detail/' + item.ref_id + '" target="_blank">' + this.$t('booking_detail') + '</a>'
              }
            }
          })
        } else {
          console.log(this.$t('error.' + resData.Code))
        }
      }).catch(error => {
        console.log(error)
      })

      this.allChecked = false
    },
    readMessage(item, index) {
      this.arrOpenMes[index] = !this.arrOpenMes[index]

      if(item.read_time > 0)
        return

      readNotice([item.notice_id]).then(res => {
        const resData = res.data
        if (resData.Success) {
          item.read_time = dayjs().unix()
          let user = this.$store.getters["account/getUser"]
          user.UnreadMesNumber -= 1
          this.$store.commit("account/setUser", user)
        } else {
          console.log(this.$t('error.' + resData.Code))
        }
      }).catch(error => {
        console.log(error)
      })
    },
    deleteSelected(){
      let seletecd = []
      this.dataPaging.Rows.forEach((item, index) => {
        if(this.arrChecked[index])
          seletecd.push(item.notice_id)
      })

      if(seletecd.length == 0)
        return

      this.arrLoading[0] = true
      deleteNotice(seletecd).then(res => {
        this.arrLoading[0] = false
        const resData = res.data
        if (resData.Success) {
          this.onSearch()
        } else {
          console.log(this.$t('error.' + resData.Code))
        }
      }).catch(error => {
        console.log(error)
      })
    },
    readSelected(){
      let seletecd = []
      this.dataPaging.Rows.forEach((item, index) => {
        if(this.arrChecked[index] && item.read_time == 0) {
          seletecd.push(item.notice_id)
        }
      })

      if(seletecd.length == 0)
        return

      this.arrLoading[1] = true
      readNotice(seletecd).then(res => {
        this.arrLoading[1] = false
        const resData = res.data
        if (resData.Success) {
          this.onSearch()
        } else {
          console.log(this.$t('error.' + resData.Code))
        }
      }).catch(error => {
        console.log(error)
      })
    },
    allRead(){
      this.arrLoading[2] = true
      readAllNotice().then(res => {
        this.arrLoading[2] = false
        const resData = res.data
        if (resData.Success) {
          this.onSearch()
        } else {
          console.log(this.$t('error.' + resData.Code))
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>
.list {
  padding: 10px 15px 20px 15px;
  margin-bottom: 30px;
}
.list .ant-divider-horizontal {
  margin: 10px 0;
}
.list strong {
  padding-left: 5px;
}
.list table {
  width: 100%;
  margin: 0 0 20px 0;
}
.list th {
  text-align: left;
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0.06);
  font-size: 13px;
  padding: 10px;
}
.list td {
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  cursor: pointer;
  vertical-align: top;
}
.list tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.01);
}
.list .read {
  color: rgba(0, 0, 0, 0.7);
}
.list .content{
  padding: 10px;
}
</style>